import "../App.css";
import "./ProjectsMob.css";
import { useState, useRef } from "react";
import { Transition } from 'react-transition-group';
import hakuto from '../img/projects/icon_hakuto_fan-01.png'
import fmeasy_logo from '../img/projects/fmeasy.png'
import Grid from "./Grid";
import GridMob from "./GridMob";

import webpart1 from '../img/projects/MSFwebparts.png'
import webpart2 from '../img/projects/MSFwebparts2.png'
import favicon1 from '../img/projects/MSFFavicon.png'
import favicon2 from '../img/projects/MSFFavicon2.png'
import msfdes from '../img/projects/MSFDescription.png'


function ProjectsMob() { 

  const word_Projects = [
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,
    0,1,1,1,1,1,1,1,1,0,1,1,0,0,1,1,0,1,1,1,1,1,1,0,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,
    0,1,1,0,0,0,0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,
    0,1,1,0,0,0,0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,1,0,1,1,0,0,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,1,1,0,0,0,1,1,1,1,1,1,1,0,
    0,1,1,0,0,0,0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,1,1,0,
    0,1,1,0,0,0,0,0,0,0,1,1,0,0,0,0,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,0,1,1,0,0,0,1,1,1,1,1,1,1,0,
  ]


  const transitionStyles = {
    entering: { 
      transform: 'scale(2.3)',
      zIndex:200
    },      
    entered:  { 
      transform: 'scale(2.3)',
      zIndex:200
    },
    exiting:  { 
      transform: 'scale(1)',
      zIndex:1
     },
    exited:   { 
      transform: 'scale(1)',
      zIndex:1
    },
  };
  
  const defaultStyle = {
    transform: 'scale(1)',
    index:1
  }

  const transitionHeadingStyles = {
    entering: { 
      transform: 'scale(0.4)',
      width: '120%'
    },      
    entered:  { 
      transform: 'scale(0.4)',
      width: '120%'
    },
    exiting:  { 
      transform: 'scale(1)',
      width: '100%'
     },
    exited:   { 
      transform: 'scale(1)',
      width: '100%'
    },
  };
  
  const defaultHeadingStyle = {
    transform: 'scale(1)',
  }

  const transitionTextStyles = {
    entering: { 
      transform: 'scale(1)',
      opacity: '1',
      height: '200px',
      width: '130%'
     },      
    entered:  { 
      transform: 'scale(1)',
      opacity: '1',
      height: '200px',
      width: '130%'
    },
    exiting:  { 
      transform: 'scale(0)',
      opacity: '0',
      height: '5px',
     },
    exited:   { 
      transform: 'scale(0)',
      opacity: '0',
      height: '5px',
    },
  };
  
  const defaultTextStyle = {
    transform: 'scale(0)',
    opacity: '0',
    height: '5px',
    width: '100%'
  }

  const [spacefication,setSpacification] = useState(false)
  const [fmeasy,setFmeasy] = useState(false)
  const [spfx,setSpfx] = useState(false)

  const spfxHandler = () => {
    if(spfx === true) setSpfxpic(null)
    setSpfx(!spfx)
  }


  const [spfxpic,setSpfxpic] = useState(null)
  const spfxpicHandler = (e,val) => {
    e.stopPropagation()
    val === spfxpic ? setSpfxpic(null) : setSpfxpic(val)
  }


  const spaceficationRef = useRef(null);
  const fmeasyRef = useRef(null);
  const spfxRef = useRef(null);

  return (
    <div className="project-m-container">
          <GridMob grid_size={60} 
                grid_item_size={1} 
                pattern={word_Projects} 
               />
        <Transition nodeRef={spaceficationRef} in={spacefication} timeout={500}> 
          {state=>(
          <div 
          className="project-m-screen" 
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          onClick={()=>setSpacification(!spacefication)}
          >
             <div className="project-m">
                <div className="project-m-bg project-bg-spacefication"/>
                <div className="project-m-bg2 project-bg2-spacefication"/>
                <div 
                     style={{
                    ...defaultHeadingStyle,
                    ...transitionHeadingStyles[state]
                    }}
                    className="project-m-text">
                    <h3>
                    Spacefication
                    </h3>
                    <span>
                      Interactive space presentation
                    </span>
                    <div style={{
                    ...defaultTextStyle,
                    ...transitionTextStyles[state]
                    }}>
                    <p>Spacefication is a small enthusiastic project which I made as a tribute to <a href="https://ispace-inc.com/"> ispace</a> startup Hakuto-R mission 1. It is an interactive presentation like webapp. which tells you a story about it's journey to Moon.</p>
                    <p>Built with React.js, HTML and CSS</p>
                    <p><strong>Click to visit:</strong></p>
                      <a href="https://spacefication.com/" >
                        <img src={hakuto} alt="hakuto-r" className="img-link"></img>   
                      </a>
                    </div>
                </div>
              </div>
          </div>)}
         </Transition>
         <Transition nodeRef={fmeasyRef} in={fmeasy} timeout={500}> 
          {state=>(
          <div 
          className="project-m-screen" 
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          onClick={()=>setFmeasy(!fmeasy)}
          >
              <div className="project-m">
                <div className="project-m-bg project-bg-spacefication"/>
                <div className="project-m-bg2 project-bg2-spacefication"/>
                <div 
                     style={{
                    ...defaultHeadingStyle,
                    ...transitionHeadingStyles[state]
                    }}
                    className="project-m-text">
                    <h3>
                    FMEASY
                    </h3>
                    <span>
                      Desktop application for FMEA/FMECA
                    </span>
                    <div style={{
                    ...defaultTextStyle,
                    ...transitionTextStyles[state]
                    }}>
                    <p>FMEAsy is a project in progress - modern desktop application prototype for FMEA and FMECA administration and processes. It takes European Space industry ECSS standarts as basis, but it will be customizable for various systems and approches.</p>
                    <p>Built with Electron.js, React.js, MySQL, HTML and CSS</p>
                    <p><strong>Check the most recent visuals and progress</strong></p>
                      <a href="https://fmeasy-app.com/" >
                        <img src={fmeasy_logo} alt="fmeasy app" width='60%'></img>   
                      </a>
                    </div>
                </div>
              </div>
          </div>)}
         </Transition> 
         <Transition nodeRef={spfxRef} in={spfx} timeout={500}> 
          {state=>(
          <div 
          className="project-m-screen" 
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          onClick={spfxHandler}
          >
              <div className="project-m">
                <div className="project-m-bg project-bg-spacefication"/>
                <div className="project-m-bg2 project-bg2-spacefication"/>
                <div 
                     style={{
                    ...defaultHeadingStyle,
                    ...transitionHeadingStyles[state]
                    }}
                    className="project-m-text"
                    >
                     {spfxpic===null? null :
                     <div className="spfx-pic" onClick={(e)=>spfxpicHandler(e,null)}>
                      <img src={spfxpic} alt="spfx"/>
                    </div>}
                    <h3>
                    MSF SPFx
                    </h3>
                    <span>
                    SharePoint Online taken to a new level
                    </span>
                    <div style={{
                    ...defaultTextStyle,
                    ...transitionTextStyles[state]
                    }}>
                    <p>As a SPO specialist in Médecins Sans Frontières (MSF), I took an initiative and created new custom webparts and addins for whole movement SharePoint and Teams (hundreds sites, thousands users).<br/>
                      Presently there are 8 new webparts in <strong>MSF webparts package</strong> (1,2) and 2 custom addins - <strong>MSF favicon</strong> (3,4) which allows set a favicon for a SPO site, and <strong>MSF library description</strong> (5) which displays Library description to users and accepts HTML with inline CSS.</p>
                    <p>Built with SPFx, Typescript, React.js, HTML and CSS</p>
                    <div className="spfx-buttons">
                      <button onClick={(e)=>spfxpicHandler(e,webpart1)}>1</button>
                      <button onClick={(e)=>spfxpicHandler(e,webpart2)}>2</button>
                      <button onClick={(e)=>spfxpicHandler(e,favicon1)}>3</button>
                      <button onClick={(e)=>spfxpicHandler(e,favicon2)}>4</button>
                      <button onClick={(e)=>spfxpicHandler(e,msfdes)}>5</button>
                    </div>
                    </div>
                </div>
              </div>
          </div>)}
         </Transition> 
    </div>    
  );
}

export default ProjectsMob;

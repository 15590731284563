import "../App.css";
import { useState } from "react";

function GridNav(props) {

  const init_pattern = props.pattern;
  const grid_size = props.grid_size
  const grid_nav_color = props.grid_nav_color

  const [randw, setRandw] = useState([0, -1]);
  const [randh, setRandh] = useState([0, -1]);
  const randHandler = ([status, idx]) => {
     if (status) {
       setRandw([Math.random() * 240 - 140, idx]);
      setRandh([Math.random() * 240 - 140, idx]);
    } else {
      setTimeout(() => {
        setRandw([0, -1]);
        setRandh([0, -1]);
      }, 3000);
    }
  };

  const [fly,setFly] = useState(false)
  const pattern = init_pattern
  const transformPattern = () => {
        props.onChangeHeadergrid([init_pattern,grid_size]) 
        props.onScrollTo() 
        setFly(true)
        setTimeout(()=>{
          setFly(false)
        },500)
  }

  const grid_item_size = '1fr '
  const grid_style = {
    gridTemplateColumns: `${grid_item_size.repeat(grid_size)}`,
  }

  return (
    <div className="grid-nav-container" style ={grid_style} onClick={transformPattern}>
      {pattern.map((item, idx) =>
        item === 1 ? (
          <div
            className="grid-nav-item"
            style={{
              top: `${fly ? Math.random() * 30 - 20 : randh[1] === idx ? randh[0] : 0}vw`,
              left: `${fly ? Math.random() * 30 - 20 : randw[1] === idx ? randw[0] : 0}vw`,
              backgroundColor: `${grid_nav_color}`,
              transition: `${fly ? Math.random()+0.5 : 0.5}s ease-out`,
              opacity: `${fly ? '0.1' : '1'}`,
            }}
            onMouseEnter={() => randHandler([true, idx])}
            onMouseLeave={() => randHandler([false, idx])}
            key={idx}
        />
        ) : (
          <div className="grid-nav-item grid-item-transparent" key={idx}/>
        )
      )}
    </div>
  );
}

export default GridNav;

import "./App.css";
import { useState, useEffect, useRef} from "react";

import Grid from "./Components/Grid";
import GridNav from "./Components/GridNav";
import GridNavMob from "./Components/GridNavMob";

import Design from "./Components/Design"
import DesignMob from "./Components/DesignMob"

import Animations from "./Components/Animations"
import AnimationsMob from "./Components/AnimationsMob"

import Projects from "./Components/Projects"
import ProjectsMob from "./Components/ProjectsMob"

import About from "./Components/About"
import AboutMob from "./Components/AboutMob"

function App() {

  const [isMobile, setIsMobile] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      const mediaQueryOrientation = window.matchMedia('(orientation: portrait)');
      setIsMobile(mediaQuery.matches && mediaQueryOrientation.matches);
      setIsLandscape(mediaQuery.matches)
    };

    handleResize();

    window.addEventListener('resize', handleResize); 

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const word_About = [
    0,1,1,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,1,0,1,1,1,1,1,1,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,1,0,1,1,1,1,1,1,0,0,0,
    0,1,1,1,1,1,1,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,0,1,1,0,0,0,0,0,    
  ]

  const word_Projects = [
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,
    0,1,1,1,1,1,1,1,1,0,1,1,0,0,1,1,0,1,1,1,1,1,1,0,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,
    0,1,1,0,0,0,0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,0,0,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,1,1,0,0,0,0,0,0,
    0,1,1,0,0,0,0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,1,0,1,1,0,0,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,1,1,0,0,0,1,1,1,1,1,1,1,0,
    0,1,1,0,0,0,0,0,0,0,1,1,0,0,0,0,0,1,1,0,0,0,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,0,0,0,0,0,1,1,0,
    0,1,1,0,0,0,0,0,0,0,1,1,0,0,0,0,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,0,1,1,0,0,0,1,1,1,1,1,1,1,0,
  ]

  const word_Design = [
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,0,0,0,1,1,0,1,1,0,0,0,0,0,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,0,1,1,0,0,1,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,1,1,0,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,
  ]

  const word_Animation = [
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,1,0,1,1,0,0,1,1,1,1,1,1,1,0,0,0,0,0,0,1,0,1,1,1,1,1,1,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,0,0,1,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,0,1,1,0,0,0,1,1,0,1,1,0,0,0,1,0,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,1,1,0,0,0,0,1,0,1,1,0,0,1,1,0,0,1,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,1,0,1,1,0,0,1,1,0,0,1,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,1,0,1,1,0,0,1,1,0,0,1,0,1,0,1,1,1,1,1,1,0,0,0,1,1,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,
    
  ]

  const word_About_v = [
    0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
0,0,0,0,1,1,0,1,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,0,0,0,0,0,
0,0,0,0,1,1,0,1,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
0,0,0,0,1,1,0,1,0,1,1,0,0,0,0,0,0,0,0,1,1,0,0,0,0,1,1,0,1,1,0,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,0,0,0,
0,0,0,0,1,1,0,1,0,1,1,1,1,1,1,1,0,0,0,1,1,0,0,0,0,1,1,0,1,1,0,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,0,0,0,
0,0,0,0,1,1,0,0,0,1,1,1,1,1,1,1,0,0,0,1,1,1,1,1,1,1,1,0,1,1,0,0,1,1,0,0,0,1,0,1,1,0,0,0,0,0,0,0,0,0,
0,0,0,0,1,1,0,0,0,1,1,0,0,1,0,1,0,0,0,1,1,1,1,1,1,1,1,0,1,1,0,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,0,0,0,
0,0,0,0,1,1,0,0,0,1,1,0,0,1,0,1,0,0,0,1,1,0,0,0,0,1,1,0,1,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,
0,0,0,0,1,1,0,0,0,1,1,0,0,1,0,1,0,0,0,1,1,0,0,0,0,1,1,0,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,0,0,0,    
  ]

  const word_Projects_v = [
    0,1,1,1,1,1,1,1,1,0,
0,1,1,1,1,1,1,1,1,0,
0,1,1,0,0,0,0,1,1,0,
0,1,1,0,0,0,0,1,1,0,
0,1,1,0,0,0,0,1,1,0,
0,1,1,1,1,1,1,1,1,0,
0,1,1,0,0,0,0,0,0,0,
0,1,1,0,0,0,0,0,0,0,
0,1,1,0,0,0,0,0,0,0,
0,1,1,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,0,0,1,1,0,0,
0,0,1,1,0,0,0,0,0,0,
0,0,1,1,0,0,0,0,0,0,
0,0,1,1,0,0,0,0,0,0,
0,0,1,1,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,0,0,0,1,0,0,
0,0,1,1,0,0,0,1,0,0,
0,0,1,1,0,0,0,1,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,1,1,0,0,
0,0,0,0,0,0,1,1,0,0,
0,0,0,0,0,0,1,1,0,0,
0,0,1,1,0,0,1,1,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,0,0,0,1,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,0,0,0,0,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,0,0,0,1,0,0,
0,0,1,1,0,0,0,0,0,0,
0,0,1,1,0,0,0,1,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,1,1,0,0,0,0,
0,0,0,0,1,1,0,0,0,0,
0,0,0,0,1,1,0,0,0,0,
0,0,0,0,1,1,0,0,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,0,0,1,1,0,0,0,0,
0,0,0,0,1,1,0,0,0,0,
0,0,0,0,1,1,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,1,1,0,0,0,0,0,0,
0,0,1,1,1,1,1,1,0,0,
0,0,0,0,0,0,0,1,0,0,
0,0,1,1,1,1,1,1,0,0,
  ]

  const word_Design_v = [
    0,1,1,1,1,1,1,1,1,0,
    0,1,1,1,1,1,1,1,1,0,
    0,1,0,0,0,0,0,1,1,0,
    0,1,0,0,0,0,0,1,1,0,
    0,1,0,0,0,0,0,1,1,0,
    0,1,0,0,0,0,0,1,1,0,
    0,1,0,0,0,0,0,1,1,0,
    0,1,0,0,0,0,0,1,1,0,
    0,1,0,0,0,0,0,1,1,0,
    0,1,1,1,1,1,1,1,1,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,0,0,0,
    0,1,1,1,1,1,1,0,0,0,
    0,1,1,0,0,0,1,0,0,0,
    0,1,1,1,1,1,1,0,0,0,
    0,1,1,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,0,0,0,
    0,1,1,1,1,1,1,0,0,0,
    0,1,1,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,0,0,0,
    0,0,0,0,0,0,1,0,0,0,
    0,1,1,1,1,1,1,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,1,1,0,0,0,0,0,
    0,0,0,1,1,0,0,0,0,0,
    0,0,0,1,1,0,0,0,0,0,
    0,0,0,1,1,0,0,0,0,0,
    0,0,0,1,1,0,0,0,0,0,
    0,0,0,1,1,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,0,0,0,
    0,1,1,1,1,1,1,0,0,0,
    0,1,1,0,0,0,0,0,0,0,
    0,1,1,0,0,1,1,0,0,0,
    0,1,1,0,0,0,1,0,0,0,
    0,1,1,1,1,1,1,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,0,0,
    0,1,1,1,1,1,1,1,0,0,
    0,1,1,0,0,0,0,1,0,0,
    0,1,1,0,0,0,0,1,0,0,
    0,1,1,0,0,0,0,1,0,0,
  ]

  const word_Animation_v = [
    0,1,1,1,1,1,1,1,1,0,
0,1,1,1,1,1,1,1,1,0,
0,1,1,0,0,0,0,1,1,0,
0,1,1,0,0,0,0,1,1,0,
0,1,1,0,0,0,0,1,1,0,
0,1,1,0,0,0,0,1,1,0,
0,1,1,1,1,1,1,1,1,0,
0,1,1,1,1,1,1,1,1,0,
0,1,1,0,0,0,0,1,1,0,
0,1,1,0,0,0,0,1,1,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,1,1,0,0,0,0,0,0,0,
0,1,1,1,1,1,1,1,0,0,
0,1,1,1,1,1,1,1,0,0,
0,1,1,0,0,0,0,1,0,0,
0,1,1,0,0,0,0,1,0,0,
0,1,1,0,0,0,0,1,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,1,1,0,0,0,0,0,0,0,
0,1,1,1,1,1,1,1,0,0,
0,1,1,1,1,1,1,1,0,0,
0,1,1,0,0,1,0,1,0,0,
0,1,1,0,0,1,0,1,0,0,
0,1,1,0,0,1,0,1,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,1,1,1,1,1,1,0,0,0,
0,0,0,0,0,0,1,0,0,0,
0,1,1,1,1,1,1,0,0,0,
0,1,1,0,0,0,1,0,0,0,
0,1,1,0,0,0,1,0,0,0,
0,1,1,1,1,1,1,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,1,1,1,1,1,1,0,0,0,
0,1,1,1,1,1,1,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,1,1,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,1,1,1,1,1,1,0,0,0,
0,1,1,1,1,1,1,0,0,0,
0,1,1,0,0,0,1,0,0,0,
0,1,1,0,0,0,1,0,0,0,
0,1,1,0,0,0,1,0,0,0,
0,1,1,1,1,1,1,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,0,0,0,0,0,0,0,0,0,
0,1,1,0,0,0,0,0,0,0,
0,1,1,1,1,1,1,1,0,0,
0,1,1,1,1,1,1,1,0,0,
0,1,1,0,0,0,0,1,0,0,
0,1,1,0,0,0,0,1,0,0,
0,1,1,0,0,0,0,1,0,0,

  ]


  const header_ref = useRef(null)
  const about_ref = useRef(null)
  const projects_ref = useRef(null)
  const animations_ref = useRef(null)
  const design_ref = useRef(null)


  const scroll = (ref) => ref.current.scrollIntoView({behavior: 'smooth' })   


  useEffect(() => {
    const handleScroll = () => {
      const about_top = about_ref.current.getBoundingClientRect().top;
      const proj_top = projects_ref.current.getBoundingClientRect().top;
      const des_top = design_ref.current.getBoundingClientRect().top;
      const anim_top = animations_ref.current.getBoundingClientRect().top;

      if(about_top > -5 && about_top < 9) setHeadergrid([word_About_v,50])
      if(proj_top > -5 && proj_top < 90) setHeadergrid([word_Projects_v,10])
      if(des_top > -5 && des_top < 90) setHeadergrid([word_Design_v,10])
      if(anim_top > -5 && anim_top < 90) setHeadergrid([word_Animation_v,10])

    };
  
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => {      
      });
      };
  },[])


  const [headergrid,setHeadergrid] = useState([word_About_v,50])
  const headergridHandler = (data) => {
      setHeadergrid(data)
  }

  const scrollHandler = (to) => {
    scroll(to)
  }


  return (
    <div className="App">
    <div  className="navigation" 
            style={{ 
            transition: '1s',
            background: 'linear-gradient(76deg, rgba(175,175,175,0.5) 0%, rgba(200,200,200,0.5) 46%, rgba(255,158,102,0.5) 70%, rgba(111,111,111,0.2) 100%)',
            clipPath: `${JSON.stringify(headergrid[0]) === JSON.stringify(word_About_v) ?
              "polygon(0% 0%, 100% 0%, 100% 12%, 86% 12%, 86% 42%, 77% 42%, 77% 55%, 68% 55%, 68% 60%, 55% 60%, 55% 53%, 44% 53%, 44% 65%, 33% 65%, 33% 57%, 24% 57%, 24% 70%, 17% 70%, 17% 80%, 2% 80%, 2% 86%, 0 86%)":
              JSON.stringify(headergrid[0]) === JSON.stringify(word_Projects_v) ? "polygon(0% 0%, 100% 0%, 100% 15%, 95% 15%, 95% 30%, 80% 30%, 80% 52%, 70% 52%, 70% 56%, 55% 56%, 55% 54%, 45% 54%, 45% 65%, 33% 65%, 33% 52%, 22% 52%, 22% 48%, 17% 48%, 17% 60%, 4% 60%, 4% 64%, 0 64%)" :
              JSON.stringify(headergrid[0]) === JSON.stringify(word_Design_v) ?
              "polygon(0% 0%, 100% 0%, 100% 20%, 92% 20%, 92% 40%, 77% 40%, 77% 50%, 62% 50%, 62% 45%, 58% 45%, 58% 52%, 44% 52%, 44% 62%, 38% 62%, 38% 57%, 30% 57%, 30% 70%, 17% 70%, 17% 80%, 10% 80%, 10% 86%, 0 86%)" : 
              "polygon(0% 0%, 96% 0%, 96% 12%, 90% 12%, 90% 24%, 78% 24%, 78% 32%, 65% 32%, 65% 40%, 57% 40%, 57% 53%, 44% 53%, 44% 60%, 33% 60%, 33% 56%, 24% 56%, 24% 70%, 18% 70%, 18% 75%, 5% 75%, 5% 86%, 0 86%)"   
       
            }`
            }} />

     <div className="navigation" 
           style={{ 
            transition: '1s',
            background: 'linear-gradient(76deg, rgba(165,165,165,0.5) 0%, rgba(210,210,210,0.5) 32%, rgba(150,150,150,0.5) 75%, rgba(255,158,102,0.3) 100%)',
            clipPath: `${JSON.stringify(headergrid[0]) === JSON.stringify(word_About_v) ?
              "polygon(0% 0%, 98% 0%, 98% 10%, 94% 10%, 94% 20%, 88% 20%, 88% 35%, 72% 35%, 72% 50%, 55% 50%, 55% 53%, 44% 53%, 44% 65%, 33% 65%, 33% 57%, 24% 57%, 24% 70%, 17% 70%, 17% 80%, 2% 80%, 2% 86%, 0 86%)":
              JSON.stringify(headergrid[0]) === JSON.stringify(word_Projects_v) ? "polygon(0% 0%, 98% 0%, 98% 38%, 95% 38%, 95% 42%, 85% 42%, 85% 46%, 68% 46%, 68% 40%, 55% 40%, 55% 52%, 46% 53%, 46% 65%, 35% 65%, 35% 55%, 25% 55%, 25% 65%, 20% 65%, 20% 72%, 2% 72%, 2% 68%, 0 68%)" :
              JSON.stringify(headergrid[0]) === JSON.stringify(word_Design_v) ?
              "polygon(0% 0%, 100% 0%, 100% 24%, 85% 24%, 85% 42%, 77% 42%, 77% 36%, 68% 36%, 68% 42%, 55% 42%, 55% 53%, 44% 53%, 44% 65%, 33% 65%, 33% 57%, 24% 57%, 24% 70%, 17% 70%, 17% 80%, 2% 80%, 2% 86%, 0 86%)" : 
              "polygon(0% 0%, 100% 0%, 100% 25%, 95% 25%, 95% 38%, 82% 38%, 82% 45%, 62% 45%, 62% 40%, 55% 40%, 55% 48%, 46% 48%, 46% 60%, 33% 60%, 33% 57%, 26% 57%, 26% 70%, 17% 70%, 17% 80%, 2% 80%, 2% 86%, 0 86%)"          
            }`
            }} />
    {isMobile?
        <div className="navigation" ref={header_ref}>
        <GridNavMob
          onChangeHeadergrid = {()=>setHeadergrid([word_About_v,50])}
          onScrollTo = {()=>scrollHandler(about_ref)}
          grid_size={40} 
          grid_nav_color={JSON.stringify(headergrid[0]) === JSON.stringify(word_About_v) ? "black" : "#3d3d3d"} 
          pattern={word_About} 
        />
        <GridNavMob 
          onChangeHeadergrid = {headergridHandler}
          onScrollTo={()=>scrollHandler(projects_ref)}
          grid_size={60} 
          grid_nav_color={JSON.stringify(headergrid[0]) === JSON.stringify(word_Projects_v) ? "black" : "#3d3d3d"} 
          pattern={word_Projects} 
          /> 
        <GridNavMob
          onChangeHeadergrid = {headergridHandler}
          onScrollTo = {()=>scrollHandler(design_ref)}
          grid_size={50} 
          grid_nav_color={JSON.stringify(headergrid[0]) === JSON.stringify(word_Design_v) ? "black" : "#3d3d3d"} 
          pattern={word_Design} 
          />
        <GridNavMob
          onChangeHeadergrid = {headergridHandler}
          onScrollTo={()=>scrollHandler(animations_ref)}
          grid_size={70} 
          grid_nav_color={JSON.stringify(headergrid[0]) === JSON.stringify(word_Animation_v) ? "black" : "#3d3d3d"} 
          pattern={word_Animation} 
          /> 
      </div> :        
    <div className="navigation" ref={header_ref}>
      <GridNav
        onChangeHeadergrid = {()=>setHeadergrid([word_About_v,50])}
        onScrollTo = {()=>scrollHandler(about_ref)}
        grid_size={40} 
        grid_nav_color={JSON.stringify(headergrid[0]) === JSON.stringify(word_About_v) ? "black" : "#3d3d3d"} 
        pattern={word_About} 
       />
      <GridNav 
        onChangeHeadergrid = {headergridHandler}
        onScrollTo={()=>scrollHandler(projects_ref)}
        grid_size={60} 
        grid_nav_color={JSON.stringify(headergrid[0]) === JSON.stringify(word_Projects_v) ? "black" : "#3d3d3d"} 
        pattern={word_Projects} 
        /> 
      <GridNav
        onChangeHeadergrid = {headergridHandler}
        onScrollTo = {()=>scrollHandler(design_ref)}
        grid_size={50} 
        grid_nav_color={JSON.stringify(headergrid[0]) === JSON.stringify(word_Design_v) ? "black" : "#3d3d3d"} 
        pattern={word_Design} 
        />
      <GridNav
        onChangeHeadergrid = {headergridHandler}
        onScrollTo={()=>scrollHandler(animations_ref)}
        grid_size={70} 
        grid_nav_color={JSON.stringify(headergrid[0]) === JSON.stringify(word_Animation_v) ? "black" : "#3d3d3d"} 
        pattern={word_Animation} 
        /> 
    </div>}
    <div className="main">
        <div className="main-content">      
          <div ref={about_ref}>
            <div>{isMobile?<AboutMob/>:<About/>}</div>
          </div>
          <div ref={projects_ref}>
            <div>{isMobile?<ProjectsMob/>:<Projects/>}</div> 
          </div>
          <div ref={design_ref}>
            <div>{isMobile?<DesignMob/>:<Design/>}</div> 
          </div>
          <div ref={animations_ref}>
           <div>{isMobile?<AnimationsMob/>:<Animations ls={isLandscape}/>}</div>
          </div>
        </div>
        {isMobile? null :
         <div className = "main-title">
          <div className = "main-title-fixed"
            style={{
              right: `${JSON.stringify(headergrid[0]) === JSON.stringify(word_About_v) ? '15%' : '0'}`,
              top: `${JSON.stringify(headergrid[0]) === JSON.stringify(word_About_v) ? '20%' : '5%'}`
            }}
          >
                <Grid grid_size={headergrid[1]} 
                grid_item_size={ JSON.stringify(headergrid[0]) === JSON.stringify(word_About_v) ? 0.5 : 0.2} 
                pattern={headergrid[0]} 
                trans_pattern={headergrid[0]}
                /> 
          </div>
        </div>}
    </div>
    <div className="footer" 
      style={{ 
        transition:'1s',
        clipPath: `${JSON.stringify(headergrid[0]) === JSON.stringify(word_About_v) ?
          "polygon(0% 100%, 0% 83%, 8% 83%, 8% 73%, 15% 73%, 15% 81%, 17% 81%, 17% 63%, 25% 63%, 25% 69%, 35% 69%, 35% 59%, 50% 59%, 50% 64%, 58% 64%, 58% 55%, 68% 55%, 68% 45%, 75% 45%, 75% 40%, 85% 40%, 85% 52%, 94% 54%, 94% 38%, 98% 38%, 98% 50%, 100% 50%, 100% 100%)":
          JSON.stringify(headergrid[0]) === JSON.stringify(word_Projects_v) ? 
          "polygon(0% 100%, 0% 84%, 6% 84%, 6% 72%, 16% 72%, 16% 80%, 20% 80%, 20% 65%, 25% 65%, 25% 72%, 35% 72%, 35% 59%, 48% 59%, 48% 62%, 58% 62%, 58% 52%, 68% 52%, 68% 56%, 73% 56%, 73% 44%, 87% 44%, 87% 40%, 95% 40%, 95% 38%, 98% 38%, 98% 46%, 100% 46%, 100% 100%)" :
          JSON.stringify(headergrid[0]) === JSON.stringify(word_Design_v) ?
          "polygon(0% 100%, 0% 85%, 10% 85%, 10% 72%, 15% 72%, 15% 68%, 18% 68%, 18% 74%, 25% 74%, 25% 64%, 38% 64%, 38% 59%, 52% 59%, 52% 64%, 58% 64%, 58% 55%, 64% 55%, 64% 45%, 72% 45%, 72% 48%, 82% 48%, 82% 42%, 90% 42%, 90% 38%, 98% 38%, 98% 43%, 100% 43%, 100% 100%)" : 
          "polygon(0% 100%, 0% 82%, 8% 82%, 8% 78%, 15% 78%, 15% 82%, 17% 82%, 17% 76%, 25% 76%, 25% 65%, 38% 65%, 38% 60%, 50% 60%, 50% 55%, 58% 55%, 58% 60%, 67% 60%, 67% 48%, 76% 48%, 76% 54%, 82% 54%, 82% 42%, 92% 42%, 92% 38%, 98% 38%, 98% 35%, 100% 35%, 100% 100%)"      
        }`
        }}
   
    > 
    <a href="https://www.linkedin.com/in/ales-bachtik/"><div className="contact linkedin"/></a>
    <a href="mailto:ales.bachtik7@gmail.com"><div className="contact mail"/></a>
    <a href="https://github.com/Ales-SITS"><div className="contact github"/></a>
    </div>
    <div className="version">v0.9.5</div>
   </div>

  );
}

export default App;
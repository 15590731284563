import "../App.css";
import "./Animation.css";
import { useState, useEffect, useRef } from "react";
import { Transition } from 'react-transition-group';
import React from 'react';
import { useInView } from 'react-intersection-observer';


function Midori (){
  return (
    <div className="animation-info-text">
      <span>Midori's journey to East - Opening video for a <a href="https://instagram.com/midoriczech?igshid=MzRlODBiNWFlZA=="> travel blog</a>. VHS goofy retro style. Music Immortal Sound Above Cloud Palace by Xu Jingqing| 2022 </span>
    </div>
  )
}

function Tintagel (){
  return (
    <div className="animation-info-text">
      <span><a href="https://www.castletintagel.com/">Tintagel Castle HEMA Group</a> - Unleashing the Art of Sword Fencing in central Tokyo. A Captivating Promotional Video for my good friends. Music: Drums of War by Rafael Krux (<a href="https://www.orchestralis.net/">orchestralis.net</a>) | 2021 </span>
    </div>
  )
}

function Tea (){
  return (
    <div className="animation-info-text">
      <span>Fleur Tea Room - spirit of Japanese tea ceremony. Opening clip for a close friend who is making lovely <a href="https://www.youtube.com/@hanatea-room5742">Hana Tea Room videoblog</a> about tea ceremony. Music: Creative commons | 2022 </span>
    </div>
  )
}

function Tour (){
  return (
    <div className="animation-info-text">
      <span>Family tour - Enjoy new Tokyo tours. Funny tour promotion video for top Tokyo tour company <a href="https://www.hatobus.co.jp/">Hato Bus Co.Ltd</a>. Music: Creative commons | 2021 </span>
    </div>
  )
}

function Corona (){
  return (
    <div className="animation-info-text">
      <span>Safety measures - Enjoy your tour safely. Video concept during Corona virus times in 2021 for top Tokyo tour company <a href="https://www.hatobus.co.jp/">Hato Bus Co.Ltd</a>. | 2021 </span>
    </div>
  )
}

function Cafe (){
  return (
    <div className="animation-info-text">
      <span>Coffee & hotdog - Enjoy a small snack before exploring Tokyo. Animated screen for <a href="https://www.hatobus.co.jp/">Hato Bus Co.Ltd</a> cafe at Tokyo Station. | 2021 </span>
    </div>
  )
}


function AnimationItem(props) { 

  
  const trans_set = props.cls === "v-midori"    ? `perspective(1000px) translateX(15vw) translateY(0vw) translateZ(-450px) scale(0.65)`  :
                    props.cls === "v-tintagel"  ? `perspective(1000px) translateX(-20vw) translateY(7vw) translateZ(-600px) scale(0.55)` :
                    props.cls === "v-tea"       ? `perspective(1000px) translateX(-10vw) translateY(-15vw) translateZ(-600px) scale(0.50)` :
                    props.cls === "v-tour"      ? `perspective(1000px) translateX(38vw) translateY(7vw) translateZ(-500px) scale(0.45)` :
                    props.cls === "v-corona"    ? `perspective(1000px) translateX(40vw) translateY(-15vw) translateZ(-800px) scale(0.4)`:
                    props.cls === "v-cafe"      ? `perspective(1000px) translateX(15vw) translateY(-19vw) translateZ(-750px) scale(0.38)` : ""

  const index_num = props.cls === "v-midori"    ? 4 :
                    props.cls === "v-tintagel"  ? 5 :
                    props.cls === "v-tea"       ? 6 :
                    props.cls === "v-tour"      ? 7 :
                    props.cls === "v-corona"    ? 8 :
                    props.cls === "v-cafe"      ? 9 : ""

  const description = props.cls === "v-midori"    ? <Midori/>   :
                      props.cls === "v-tintagel"  ? <Tintagel/> :
                      props.cls === "v-tea"       ? <Tea/> :
                      props.cls === "v-tour"      ? <Tour/>     :
                      props.cls === "v-corona"    ? <Corona/>   :
                      props.cls === "v-cafe"      ? <Cafe/>     : null


  const [hovered,setHovered] = useState(false)
  const hoveredHandler = (status) => {
    setHovered(status)
  }

  const transitionStyles = {
    entering: { 
      transform: `${props.ls ?
         'perspective(500px) translateX(20vw) translateY(0) translateY(0vw) scale(0.8) rotateY(13deg)':
         'perspective(1000px) translateX(5vw) translateY(0) translateY(0vw) scale(1) rotateY(15deg)'}`, 
      opacity: 1,
      filter: 'grayscale(0)',
      clipPath: 'polygon(0% 4%, 6% 4%, 6% 3%, 28% 3%, 28% 2%, 30% 2%, 30% 1%, 36% 1%, 36% 2%, 52% 2%, 52% 1%, 64% 1%, 64% 3%, 80% 3%, 80% 1%, 85% 1%, 85% 0%, 95% 0%, 95% 2%, 100% 2%, 100% 98.5%, 87% 98.5%, 87% 100%, 70% 100%, 70% 99.5%, 56% 99.5%, 56% 99%, 43% 99%, 43% 100%, 25% 100%, 25% 99.5%, 14% 99.5%, 14% 100%, 0% 100%)',
      zIndex: 11
    },      
    entered:  { 
 transform: `${props.ls ?
         'perspective(500px) translateX(20vw) translateY(0) translateY(-2vw) scale(0.8) rotateY(13deg)':
         'perspective(1000px) translateX(5vw) translateY(0) translateY(0vw) scale(1) rotateY(15deg)'}`,  
      opacity: 1,
      filter: 'grayscale(0)',
      clipPath: 'polygon(0% 4%, 6% 4%, 6% 3%, 28% 3%, 28% 2%, 30% 2%, 30% 1%, 36% 1%, 36% 2%, 52% 2%, 52% 1%, 64% 1%, 64% 3%, 80% 3%, 80% 1%, 85% 1%, 85% 0%, 95% 0%, 95% 2%, 100% 2%, 100% 98.5%, 87% 98.5%, 87% 100%, 70% 100%, 70% 99.5%, 56% 99.5%, 56% 99%, 43% 99%, 43% 100%, 25% 100%, 25% 99.5%, 14% 99.5%, 14% 100%, 0% 100%)',
      zIndex: 11
  
    },
    exiting:  { 
      transform: `${trans_set}`, 
      opacity: `${hovered? 0.8 : 0.5}`,
      filter: `${hovered? 'grayscale(0.1)' : 'grayscale(0.7)'}`,
      clipPath: 'polygon(0% 0%, 6% 0%, 6% 0%, 28% 0%, 28% 0%, 30% 0%, 30% 0%, 36% 0%, 36% 0%, 52% 0%, 52% 0%, 64% 0%, 64% 0%, 80% 0%, 80% 0%, 85% 0%, 85% 0%, 95% 0%, 95% 0%, 100% 0%, 100% 100%, 87% 100%, 87% 100%, 70% 100%, 70% 100%, 56% 100%, 56% 100%, 43% 100%, 43% 100%, 25% 100%, 25% 100%, 14% 100%, 14% 100%, 0% 100%)',
      zIndex: {index_num}
     },
    exited:   { 
      transform: `${trans_set}`, 
      opacity: `${hovered? 0.8 : 0.5}`,
      filter: `${hovered? 'grayscale(0.1)' : 'grayscale(0.7)'}`,
      clipPath: 'polygon(0% 0%, 6% 0%, 6% 0%, 28% 0%, 28% 0%, 30% 0%, 30% 0%, 36% 0%, 36% 0%, 52% 0%, 52% 0%, 64% 0%, 64% 0%, 80% 0%, 80% 0%, 85% 0%, 85% 0%, 95% 0%, 95% 0%, 100% 0%, 100% 100%, 87% 100%, 87% 100%, 70% 100%, 70% 100%, 56% 100%, 56% 100%, 43% 100%, 43% 100%, 25% 100%, 25% 100%, 14% 100%, 14% 100%, 0% 100%)',
      zIndex: {index_num}
    },
  };
  
  const defaultStyle = {
    transform: `${trans_set}`, 
    opacity: `${hovered? 0.8 : 0.5}`,
    filter: `${hovered? 'grayscale(0.1)' : 'grayscale(0.7)'}`,
    zIndex: {index_num},
    clipPath: 'polygon(0% 0%, 6% 0%, 6% 0%, 28% 0%, 28% 0%, 30% 0%, 30% 0%, 36% 0%, 36% 0%, 52% 0%, 52% 0%, 64% 0%, 64% 0%, 80% 0%, 80% 0%, 85% 0%, 85% 0%, 95% 0%, 95% 0%, 100% 0%, 100% 100%, 87% 100%, 87% 100%, 70% 100%, 70% 100%, 56% 100%, 56% 100%, 43% 100%, 43% 100%, 25% 100%, 25% 100%, 14% 100%, 14% 100%, 0% 100%)',
    transition: '500ms ease-in-out',
  }


  const selectedHandler = (status) => {
    status === false ? props.onSelectedvideo("") : props.onSelectedvideo(props.video) 
  } 
 
  const [visibleref, inView, entry] = useInView({
    threshold: 0,
  });
  
  const nodeRef = useRef(null);
  const vidRef = useRef(null);

  useEffect(()=>{
    inView ? vidRef.current.play() : vidRef.current.pause() 
  },[inView])

   return (
         <Transition nodeRef={nodeRef} in={props.selected} timeout={300}> 
              {state=>(
                <div
                   ref={visibleref}
                   onClick={(e)=>selectedHandler(!props.selected)} 
                   onMouseEnter={()=>hoveredHandler(true)}
                   onMouseLeave={()=>hoveredHandler(false)}
                   className={`animations-modal ${props.cls}`} 
                   style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                  }}>
                  {!props.selected ? <div className='video-selector'  onClick={()=>selectedHandler(!props.selected)} /> : null}
                  <video ref={vidRef} width="100%" height="auto" controls preload="metadata" muted loop>
                      <source src={props.video} type="video/mp4"/>
                  </video>
                  {props.selected ? description : null}
                </div>
              )}                
          </Transition>     
   
  );
}

export default AnimationItem;



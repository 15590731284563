import "../App.css";
import "./DesignMob.css";
import { useState} from "react";
import GridMob from "./GridMob";

import leaf1 from '../img/leaflets/leaf-1.jpg'
import leaf2 from '../img/leaflets/leaf-2.jpg'
import leaf3 from '../img/leaflets/leaf-3.jpg'
import leaf4 from '../img/leaflets/leaf-4.jpg'
import leaf5 from '../img/leaflets/leaf-5.jpg'
import leaf6 from '../img/leaflets/leaf-6.jpg'
import leaf7 from '../img/leaflets/leaf-7.jpg'
import leaf8 from '../img/leaflets/leaf-8.jpg'

import poster1 from '../img/posters/poster-1.jpg'
import poster2 from '../img/posters/poster-2.jpg'
import poster3 from '../img/posters/poster-3.jpg'

import other1 from '../img/other/other-1.jpg'
import other2 from '../img/other/other-2.jpg'
import other3 from '../img/other/other-3.jpg'

const word_Design = [
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,0,0,0,1,1,0,1,1,0,0,0,0,0,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,0,1,1,0,0,1,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,1,1,0,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,
  ]

const leaf1_info = {
    src: leaf1,
    text: "Simple colorfull leafleat with Tokyo autumn tours.",
    company: "Hato Bus CO.LTD.",
    year: "2021",
}

const leaf2_info = {
    src: leaf2,
    text: "Fancy brochure for a Tokyo tour with a luxury bus and services.",
    company: "Hato Bus CO.LTD.",
    year: "2021",
}

const leaf3_info = {
    src: leaf3,
    text: "International Tour Full-package poster and leaflet design (with origami features)",
    company: "Hato Bus CO.LTD.",
    year: "2021",
}

const leaf4_info = {
    src: leaf4,
    text: "International Tour Full-package poster and leaflet design (with origami features)",
    company: "Hato Bus CO.LTD.",
    year: "2022",
}

const leaf5_info = {
    src: leaf5,
    text: "Conference brochure",
    company: "Hato Bus CO.LTD.",
    year: "Freelance",
}

const leaf6_info = {
    src: leaf6,
    text: "Conference brochure",
    company: "Freelance",
    year: "2022",
}

const leaf7_info = {
    src: leaf7,
    text: "Seasonal brochure for Tokyo area autumn tours and luxury bus tours",
    company: "Freelance",
    year: "2021",
}

const leaf8_info = {
    src: leaf8,
    text: "Seasonal pamhlet for Tokyo and Yokohama area summer tours",
    company: "Freelance",
    year: "2021",
}

const poster3_info = {
    src: poster3,
    text: "A display advertising a tour to magnificent nature location.",
    company: "Hato Bus CO.LTD.",
    year: "2020",
}

const poster1_info = {
    src: poster1,
    text: "A poster advertising a Tokyo tour with a luxury bus and services.",
    company: "Hato Bus CO.LTD.",
    year: "2021",
}

const poster2_info = {
    src: poster2,
    text: "An animated café menu display in Tokyo station",
    company: "Hato Bus CO.LTD.",
    year: "2022",
}

const other1_info = {
    src: other1,
    text: "Diploma reward for a finner of HEMA fencing tournament.(1/2)",
    company: "Freelancer",
    year: "2021",
}

const other2_info = {
    src: other2,
    text: "Diploma reward for a finner of HEMA fencing tournament.(2/2)",
    company: "Freelancer",
    year: "2021",
}

const other3_info = {
    src: other3,
    text: "Designed A1 list of all tours planned for August 2020, displayed on Tokyo Station Tours bus stop.",
    company: "Hato Bus CO.LTD.",
    year: "2020",
}

const leaf_arr = [leaf1_info,leaf2_info,leaf3_info,leaf4_info,leaf5_info,leaf6_info,leaf7_info,leaf8_info]
const poster_arr = [poster1_info,poster2_info,poster3_info]
const other_arr = [other1_info,other2_info,other3_info]

function DesignMob() {

  const [selectedmob,setSelectedMob] = useState(0)
  const selectedmobHandler = (val) => {
    setSelectedMob(val)
  }

  const [modalmobpic,setModalmobpic] = useState(leaf1_info)
  const [modalmob, setModalmob] = useState (false)
  const modalmobHandler = (pic) => {
    setModalmob(!modalmob)
    setModalmobpic(pic)
  }


  const gallery_arr = [leaf_arr,poster_arr,other_arr]

  

  return (
      <div className="design-m-container">
          <GridMob grid_size={50} 
                grid_item_size={1} 
                pattern={word_Design} 
                />
        <div className="design-m-intro">
            <div>
                Pamhlets, posters, graphic... It is always fun to make. It is also a perfect way how to learn more about customers, target groups, skill necessary for most of creative work in general. <br/>
                My tools? Illustrator, Photoshop, InDesign and humour.
            </div>
        </div>
        <div className="design-m-nav">
            <button onClick={()=>{selectedmobHandler(0)}}
                    style={{
                        backgroundColor: `${selectedmob===0? 
                        'rgba(133, 133, 133, 0.9)' : 'rgba(133, 133, 133, 0.5)'}`,
                        color: `${selectedmob===0? 
                            'white' : 'black'}`,
                        clipPath: `${selectedmob===0 ? 'polygon(19% 0%, 19% 0%, 33% 0%, 33% 0%, 72% 0%, 72% 0%, 89% 0%, 89% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%)' : 'polygon(19% 0%, 19% 6%, 33% 6%, 33% 0%, 72% 0%, 72% 7%, 89% 7%, 89% 12%, 100% 12%, 100% 100%, 0% 100%, 0% 0%)'}`
                    }}
            >Brochures & leaflets</button>
            <button onClick={()=>{selectedmobHandler(1)}}
            style={{
                backgroundColor: `${selectedmob===1? 
                'rgba(133, 133, 133, 0.9)' : 'rgba(133, 133, 133, 0.5)'}`,
                color: `${selectedmob===1? 
                    'white' : 'black'}`,
                clipPath: `${selectedmob===1 ? 'polygon(19% 0%, 19% 0%, 33% 0%, 33% 0%, 72% 0%, 72% 0%, 89% 0%, 89% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%)' : 'polygon(16% 12%, 16% 2%, 33% 2%, 33% 6%, 65% 6%, 65% 1%, 89% 1%, 89% 12%, 100% 12%, 100% 100%, 0% 100%, 0% 12%)'}`
            }}
            >Posters & Displays</button>
            <button onClick={()=>{selectedmobHandler(2)}}
            style={{
                backgroundColor: `${selectedmob===2? 
                'rgba(133, 133, 133, 0.9)' : 'rgba(133, 133, 133, 0.5)'}`,
                color: `${selectedmob===2? 
                    'white' : 'black'}`,
                clipPath: `${selectedmob===2 ? 'polygon(19% 0%, 19% 0%, 33% 0%, 33% 0%, 72% 0%, 72% 0%, 89% 0%, 89% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%)' : 'polygon(5% 0%, 5% 6%, 30% 6%, 30% 10%, 70% 10%, 70% 2%, 98% 2%, 98% 6%, 100% 12%, 100% 100%, 0% 100%, 0% 0%)'}`
            }}>Miscellaneous</button>
        </div>
        <div className="design-m-gallery">
        {gallery_arr[selectedmob].map((item,idx)=>
                                    <div 
                                    key={idx} 
                                    alt={item.src} 
                                    className='mini-m-clip'
                                    style = {{backgroundImage: `url(${item.src})`}}
                                    onClick={()=>modalmobHandler(item)}/>
                            )}
        </div>
            {modalmob ? 
            <div className="design-m-modal" onClick={modalmobHandler}>
                <img src={modalmobpic.src} alt=""/>
                <div className="design-m-modal-text">
                    <span>{modalmobpic.text}</span><br/>
                    <span>{modalmobpic.company}</span> | <span>{modalmobpic.year}</span>          
                </div>
            </div>
            :null}
      </div>   
  );
}

export default DesignMob;
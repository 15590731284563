import "../App.css";
import "./About.css";

function About() { 
  return (
    <div className="about-container">
         <div className="about-screen">
          <div className="about-slider">
          <h3>a creative one ...</h3> 
              <div className="slider-box">
                <span>DEVELOPER</span>
                <span>DESIGNER</span>
                <span>ANIMATOR</span>
                <span>JAPANOLOGIST</span> 
                <span>TRAVELER</span>
                <span>BAD SINGER</span>
                <span>and</span>
                <span>COOKIE BAKER!</span>
                <span>and more of course</span>   
              </div>

          </div>          
   
         </div>
    </div>    
  );
}

export default About;

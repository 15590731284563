import "../App.css";
import "./Design.css";
import { useState} from "react";

import leaf1 from '../img/leaflets/leaf-1.jpg'
import leaf2 from '../img/leaflets/leaf-2.jpg'
import leaf3 from '../img/leaflets/leaf-3.jpg'
import leaf4 from '../img/leaflets/leaf-4.jpg'
import leaf5 from '../img/leaflets/leaf-5.jpg'
import leaf6 from '../img/leaflets/leaf-6.jpg'
import leaf7 from '../img/leaflets/leaf-7.jpg'
import leaf8 from '../img/leaflets/leaf-8.jpg'

import poster1 from '../img/posters/poster-1.jpg'
import poster2 from '../img/posters/poster-2.jpg'
import poster3 from '../img/posters/poster-3.jpg'

import other1 from '../img/other/other-1.jpg'
import other2 from '../img/other/other-2.jpg'
import other3 from '../img/other/other-3.jpg'


const leaf1_info = {
    src: leaf1,
    text: "Simple colorfull leafleat with Tokyo autumn tours.",
    company: "Hato Bus CO.LTD.",
    year: "2021",
}

const leaf2_info = {
    src: leaf2,
    text: "Fancy brochure for a Tokyo tour with a luxury bus and services.",
    company: "Hato Bus CO.LTD.",
    year: "2021",
}

const leaf3_info = {
    src: leaf3,
    text: "International Tour Full-package poster and leaflet design (with origami features)",
    company: "Hato Bus CO.LTD.",
    year: "2021",
}

const leaf4_info = {
    src: leaf4,
    text: "International Tour Full-package poster and leaflet design (with origami features)",
    company: "Hato Bus CO.LTD.",
    year: "2022",
}

const leaf5_info = {
    src: leaf5,
    text: "Conference brochure",
    company: "Hato Bus CO.LTD.",
    year: "2022",
}

const leaf6_info = {
    src: leaf6,
    text: "Conference brochure",
    company: "Freelance",
    year: "2022",
}

const leaf7_info = {
    src: leaf7,
    text: "Seasonal brochure for Tokyo area autumn tours and luxury bus tours",
    company: "Freelance",
    year: "2021",
}

const leaf8_info = {
    src: leaf8,
    text: "Seasonal pamhlet for Tokyo and Yokohama area summer tours",
    company: "Freelance",
    year: "2021",
}

const poster3_info = {
    src: poster3,
    text: "A display advertising a tour to magnificent nature location.",
    company: "Hato Bus CO.LTD.",
    year: "2020",
}

const poster1_info = {
    src: poster1,
    text: "A poster advertising a Tokyo tour with a luxury bus and services.",
    company: "Hato Bus CO.LTD.",
    year: "2021",
}

const poster2_info = {
    src: poster2,
    text: "An animated café menu display in Tokyo station",
    company: "Hato Bus CO.LTD.",
    year: "2022",
}

const other1_info = {
    src: other1,
    text: "Diploma reward for a winner of HEMA fencing tournament.(1/2)",
    company: "Freelancer",
    year: "2021",
}

const other2_info = {
    src: other2,
    text: "Diploma reward for a finner of HEMA fencing tournament.(2/2)",
    company: "Freelancer",
    year: "2021",
}

const other3_info = {
    src: other3,
    text: "Designed A1 list of all tours planned for August 2020, displayed on Tokyo Station Tours bus stop.",
    company: "Hato Bus CO.LTD.",
    year: "2020",
}



const leaf_arr = [leaf1_info,leaf2_info,leaf3_info,leaf4_info,leaf5_info,leaf6_info,leaf7_info,leaf8_info]
const poster_arr = [poster1_info,poster2_info,poster3_info]
const other_arr = [other1_info,other2_info,other3_info]

function Design(props) {


     const gallery_info_content = [
        {
            header: '_',
            content: []
        },
        {   
            header: 'Brochures & leaflets',
            content: leaf_arr
        },
        {   
            header: 'Posters & Displays',
            content: poster_arr
        } ,
        {
            header: 'Miscellaneous',
            content: other_arr
          }  
        ]
    
    const [galleryinfo, setGalleryinfo] = useState(0)
    const [galleryselected,setGalleryselected] = useState(false)
    const gallerinfoHandler = (e,selected,display) => {
            e.stopPropagation()
            selected === galleryinfo ? setGalleryinfo(0) : setGalleryinfo(selected)
            selected === 0 || selected === galleryinfo ? setGalleryselected(false) : setGalleryselected(true) 
            setImginfo(display)         
    }
  
    const [galleryrotate,setGalleryrotate] = useState(20)
    const galleryRotateHandler = (val) => {
            props.mob===true? setGalleryrotate(0) : setGalleryrotate(val)
    }

   
    const [displayA,setDisplayA] = useState(leaf3_info)
    const [displayB,setDisplayB] = useState(poster1_info)
    const [displayC,setDisplayC] = useState(other2_info)
    const [imginfo,setImginfo] = useState(leaf1_info)

    const displayHandler = (e,pic,info) => {
            e.stopPropagation()
            setImginfo(pic)
            if(info===1) setDisplayA(pic)
            if(info===2) setDisplayB(pic)
            if(info===3) setDisplayC(pic)
    }


  return (
      <div className="design-container" onClick={(e)=>{gallerinfoHandler(e,0,imginfo)}}>
          <div className="design-block-shadow" >
            <div><div className="design-though" style={{opacity:`${galleryselected === true? 0 : 1 }`}}>?</div></div>
            <div className="design-about" style={{opacity:`${galleryselected === true? 0 : 1 }`}}>
                    <p>
                    <span className="design-highlight">PAMHLETS</span>,  <span className="design-highlight">POSTERS</span>,  <span className="design-highlight">GRAPHICS</span>... always fun to make, but it is also a perfect way how to learn more about people, what do they expect, what do they want to see, what they like and what they might think. <br/>
                    My tools? Illustrator, Photoshop, InDesign and a bit of humour :)
                    </p>
                    <span><strong>Hover and Click on Gallery to explore</strong></span>
            </div>
          </div>   
          <div className="design-block-main"
            style={{
              clipPath: `${galleryselected === true ?
                'polygon(0 0, 97% 0, 97% 34%, 95% 34%, 95% 48%, 96% 48%, 96% 74%, 90% 74%, 90% 89%, 87% 89%, 87% 100%, 0% 100%)' : 
                galleryselected === false  ?
                'polygon(0 0, 62% 0, 62% 34%, 65% 34%, 65% 48%, 64% 48%, 64% 74%, 69% 74%, 69% 89%, 82% 89%, 82% 100%, 0% 100%)' : 
                'none'}`
            }}
          >
                <div className="design-gallery-block">
                    <div className="container"
                        style={{
                            transform: `perspective(1000px) rotateY(${galleryselected === true ? '0deg' : galleryrotate}deg) scale(1)`,
                            clipPath: `${galleryselected === true ?
                                'polygon(0% 0%, 9% 0%, 9% 0%, 16% 0%, 16% 0%, 67% 0%, 67% 0%, 75% 0%, 75% 0%, 81% 0%, 81% 0%, 93% 0%, 93% 0%, 100% 0%, 100% 52%, 100% 52%, 100% 59%, 100% 59%, 100% 95%, 100% 95%, 100% 100%, 60% 100%, 60% 100%, 54% 100%, 54% 100%, 24% 100%, 24% 100%, 1% 100%, 1% 100%, 0% 100%, 0% 29%, 0% 29%, 0% 21%, 0% 21%)':
                                 'polygon(0% 0%, 9% 0%, 9% 5%, 16% 5%, 16% 0%, 67% 0%, 67% 5%, 75% 5%, 75% 2%, 81% 2%, 81% 0%, 93% 0%, 93% 4%, 100% 4%, 100% 52%, 97% 52%, 97% 59%, 100% 59%, 100% 95%, 87% 95%, 87% 100%, 60% 100%, 60% 97%, 54% 97%, 54% 100%, 24% 100%, 24% 96%, 1% 96%, 1% 85%, 0% 85%, 0% 29%, 3% 29%, 3% 21%, 0% 21%)'}`
                            }}
                        onMouseEnter={()=>galleryRotateHandler(0)}
                        onMouseLeave={()=>galleryRotateHandler(10)}>

                        <div className={`clip clip1 ${galleryinfo=== 1 ? 'clip-selected' : ''}`} 
                             style = {{backgroundImage: `url(${displayA.src})`, backgroundSize: `${galleryinfo === 1 ? 'contain' : 'contain'}`,  backgroundPosition:"left"}} 
                             onClick={(e)=>gallerinfoHandler(e,1,displayA)}/>

                        <div className={`clip clip2 ${galleryinfo=== 2 ? 'clip-selected' : ''}`}
                             style = {{backgroundImage: `url(${displayB.src})`, backgroundSize: `${galleryinfo === 2 ? 'contain' : 'contain'}`, backgroundPosition:"center"}}
                             onClick={(e)=>gallerinfoHandler(e,2,displayB)}/>

                        <div className={`clip clip3 ${galleryinfo=== 3 ? 'clip-selected' : ''}`}
                             style = {{backgroundImage: `url(${displayC.src})`, backgroundSize: `${galleryinfo === 3 ? 'contain' : 'contain'}`, backgroundPosition:"right"}}
                             onClick={(e)=>gallerinfoHandler(e,3,displayC)}/>
                    </div>
                </div>
                <div className="design-info-block">                 
                          <div className="design-info-details">
                            <h3 className="design-info-details-header">{gallery_info_content[galleryinfo].header}</h3>
                            <span className="design-info-details-text">{imginfo.text}</span>
                            <div className="design-info-details-other">
                                <div className="design-info-details-year">{imginfo.year}</div>|
                                <div className="design-info-details-company">{imginfo.company}</div> 
                            </div>                         
                            <span></span>
                          </div>
                          <div className="design-gallery">
                            {gallery_info_content[galleryinfo].content.map((item,idx)=>
                                    <div 
                                    key={idx} 
                                    alt={item.src} 
                                    className='mini-clip'
                                    style = {{backgroundImage: `url(${item.src})`}}
                                    onClick={(e)=>displayHandler(e,item,galleryinfo)}/>
                            )}

                          </div>
                </div>
          </div>
       </div>    
  );
}

export default Design;
import "../App.css";

function GridNavMob(props) {

  const init_pattern = props.pattern;
  const grid_size = props.grid_size
  const grid_nav_color = props.grid_nav_color

  const pattern = init_pattern
  const transformPattern = () => {
        props.onChangeHeadergrid([init_pattern,grid_size]) 
        props.onScrollTo() 
  }

  const grid_item_size = '1fr '
  const grid_style = {
    gridTemplateColumns: `${grid_item_size.repeat(grid_size)}`,
  }

  return (
    <div className="grid-nav-container" style ={grid_style} onClick={transformPattern}>
      {pattern.map((item, idx) =>
        item === 1 ? (
          <div
            className="grid-nav-item"
            style={{
              top: '0vw',
              left: '0vw',
              backgroundColor: `${grid_nav_color}`,
              transition: '0.5s ease-out',
            }}
            key={idx}
        />
        ) : (
          <div className="grid-nav-item grid-item-transparent" key={idx}/>
        )
      )}
    </div>
  );
}

export default GridNavMob;

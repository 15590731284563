import "../App.css";
import "./AnimationMob.css";

import AnimationItemMob from './AnimationItemMob'
import GridMob from "./GridMob";

import Midori from '../video/Midori_VHS.mp4'
import Tintagel from '../video/Tintagel.m4v'
import Tea from '../video/FleurTeaRoom.mp4'
import Tour from '../video/Anim.m4v'
import Corona from '../video/corona.mp4'
import Cafe from '../video/Cafemenuloop.m4v'



function AnimationsMob() { 
  const word_Animation = [
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,0,0,1,1,0,0,1,1,0,0,0,0,0,0,1,1,1,1,1,1,0,1,1,1,1,1,1,0,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,0,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,1,1,1,1,1,0,1,1,0,0,1,1,1,1,1,1,1,0,0,0,0,0,0,1,0,1,1,1,1,1,1,0,1,1,0,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,1,1,1,1,1,1,1,0,1,1,0,0,1,1,1,1,1,1,1,0,1,1,1,1,1,1,0,0,0,1,1,0,0,0,1,1,0,1,1,0,0,0,1,0,1,1,1,1,1,1,1,0,0,0,0,0,0,0,0,0,
    0,1,1,1,1,1,1,1,1,0,1,1,0,0,0,0,1,0,1,1,0,0,1,1,0,0,1,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,1,0,1,1,0,0,1,1,0,0,1,0,1,0,1,1,0,0,0,1,0,0,0,1,1,0,0,0,1,1,0,1,1,0,0,0,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,
    0,1,1,0,0,0,0,1,1,0,1,1,0,0,0,0,1,0,1,1,0,0,1,1,0,0,1,0,1,0,1,1,1,1,1,1,0,0,0,1,1,0,0,0,1,1,0,1,1,1,1,1,1,0,1,1,0,0,0,0,1,0,0,0,0,0,0,0,0,0,
    
  ]

  return (
    <div className="animation-m-container">
                <GridMob grid_size={70} 
                grid_item_size={1} 
                pattern={word_Animation} 
                />
         <div className="animations-m-info-block">
            <div  className="animations-m-info">
              <p>
              I love animation—it's a hobby of mine and a crucial part of my creative work. While I don't undertake full animation clip projects frequently, when I do, It is always for an interesting and fun project. Take a look! 
              </p>
            </div>      
         </div>   
         <div className="animations-m-screen">
            <AnimationItemMob video={Midori}     cls={"v-midori"}/>
            <AnimationItemMob video={Tintagel}   cls={"v-tintagel"}/>
            <AnimationItemMob video={Tea}        cls={"v-tea"}/>   
            <AnimationItemMob video={Tour}       cls={"v-tour"}/>
            <AnimationItemMob video={Corona}     cls={"v-corona"}/>  
            <AnimationItemMob video={Cafe}       cls={"v-cafe"}/>                                   
         </div>
    </div>    
  );
}

export default AnimationsMob;
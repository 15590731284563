import "../App.css";

function GridMob(props) {

  const init_pattern = props.pattern;
  const grid_size = props.grid_size



  const grid_item_size = `${props.grid_item_size}vw `

  const grid_style = {
    gridTemplateColumns: `${grid_item_size.repeat(grid_size)}`,
  }

  return (
    <div className="grid-container" style ={grid_style}        >
      {init_pattern.map((item, idx) =>
        item === 1 ? (
          <div
            className="grid-item"
            style={{
              top: `0vw`,
              left: `0vw`,
              transition: `0.8s ease-out`,
              opacity: `1`,
              width: `${props.grid_item_size}vw`,
              height:`${props.grid_item_size}vw`,
            }}
            key={idx}
        />
        ) : (
          <div className="grid-item grid-item-transparent" key={idx}
          style={{
            width: `${props.grid_item_size}vw`,
            height:`${props.grid_item_size}vw`,
          }}/>
        )
      )}
    </div>
  );
}

export default GridMob;

import "../App.css";
import "./AnimationMob.css";
import { useState, useRef } from "react";
import { Transition } from 'react-transition-group';
import React from 'react';



function Midori (){
  return (
    <div className="animation-m-info-text">
      <span>Midori's journey to East - Opening video for a <a href="https://instagram.com/midoriczech?igshid=MzRlODBiNWFlZA=="> travel blog</a>. VHS goofy retro style. Music Immortal Sound Above Cloud Palace by Xu Jingqing| 2022 </span>
    </div>
  )
}

function Tintagel (){
  return (
    <div className="animation-m-info-text">
      <span><a href="https://www.castletintagel.com/">Tintagel Castle HEMA Group</a> - Unleashing the Art of Sword Fencing in central Tokyo. A Captivating Promotional Video for my good friends. Music: Drums of War by Rafael Krux (<a href="https://www.orchestralis.net/">orchestralis.net</a>) | 2021 </span>
    </div>
  )
}

function Tea (){
  return (
    <div className="animation-m-info-text">
      <span>Fleur Tea Room - spirit of Japanese tea ceremony. Opening clip for a close friend who is making lovely <a href="https://www.youtube.com/@hanatea-room5742">Hana Tea Room videoblog</a> about tea ceremony. Music: Creative commons | 2022 </span>
    </div>
  )
}

function Tour (){
  return (
    <div className="animation-m-info-text">
      <span>Family tour - Enjoy new Tokyo tours. Funny tour promotion video for top Tokyo tour company <a href="https://www.hatobus.co.jp/">Hato Bus Co.Ltd</a>. Music: Creative commons | 2021 </span>
    </div>
  )
}

function Corona (){
  return (
    <div className="animation-m-info-text">
      <span>Safety measures - Enjoy your tour safely. Video concept during Corona virus times in 2021 for top Tokyo tour company <a href="https://www.hatobus.co.jp/">Hato Bus Co.Ltd</a>. | 2021 </span>
    </div>
  )
}

function Cafe (){
  return (
    <div className="animation-m-info-text">
      <span>Coffee & hotdog - Enjoy a small snack before exploring Tokyo. Animated screen for <a href="https://www.hatobus.co.jp/">Hato Bus Co.Ltd</a> cafe at Tokyo Station. | 2021 </span>
    </div>
  )
}


function AnimationItemMob(props) { 

  const trans_set = props.cls === "v-midori"    ? `perspective(1000px) translateX(-28vw)  translateY(-30vw) translateZ(-250px) scale(0.55) rotateY(15deg)`  :
                    props.cls === "v-tintagel"  ? `perspective(1000px) translateX(28vw)   translateY(-30vw) translateZ(-250px) scale(0.55) rotateY(-15deg)` :
                    props.cls === "v-tea"       ? `perspective(1000px) translateX(28vw)   translateY(25vw)   translateZ(-250px) scale(0.52) rotateY(-15deg)` :
                    props.cls === "v-tour"      ? `perspective(1000px) translateX(-28vw)  translateY(25vw)  translateZ(-250px) scale(0.52) rotateY(15deg)` :
                    props.cls === "v-corona"    ? `perspective(1000px) translateX(25vw)   translateY(75vw)  translateZ(-250px) scale(0.45) rotateY(-15deg)`:
                    props.cls === "v-cafe"      ? `perspective(1000px) translateX(-25vw)  translateY(75vw)  translateZ(-250px) scale(0.45) rotateY(15deg)` : ""

  const index_num = props.cls === "v-midori"    ? 4 :
                    props.cls === "v-tintagel"  ? 5 :
                    props.cls === "v-tea"       ? 6 :
                    props.cls === "v-tour"      ? 7 :
                    props.cls === "v-corona"    ? 8 :
                    props.cls === "v-cafe"      ? 9 : ""

  const description = props.cls === "v-midori"    ? <Midori/>   :
                      props.cls === "v-tintagel"  ? <Tintagel/> :
                      props.cls === "v-tea"       ? <Tea/> :
                      props.cls === "v-tour"      ? <Tour/>     :
                      props.cls === "v-corona"    ? <Corona/>   :
                      props.cls === "v-cafe"      ? <Cafe/>     : null


  const transitionStyles = {
    entering: { 
      transform: `perspective(1000px) translateX(0vw) translateY(0) translateY(0vw) scale(1) rotateY(0deg)`, 
      opacity: 1,
      backgroundColor: 'rgb(91, 91, 91,0.9)',
      clipPath: 'polygon(0% 4%, 6% 4%, 6% 3%, 28% 3%, 28% 2%, 30% 2%, 30% 1%, 36% 1%, 36% 2%, 52% 2%, 52% 1%, 64% 1%, 64% 3%, 80% 3%, 80% 1%, 85% 1%, 85% 0%, 95% 0%, 95% 2%, 100% 2%, 100% 98.5%, 87% 98.5%, 87% 100%, 70% 100%, 70% 99.5%, 56% 99.5%, 56% 99%, 43% 99%, 43% 100%, 25% 100%, 25% 99.5%, 14% 99.5%, 14% 100%, 0% 100%)',
      zIndex: 11
    },      
    entered:  { 
      transform: `perspective(1000px) translateX(0vw) translateY(0) translateY(0) scale(1) rotateY(0deg)`,
      backgroundColor: 'rgb(91, 91, 91,0.9)',  
      opacity: 1,
      clipPath: 'polygon(0% 4%, 6% 4%, 6% 3%, 28% 3%, 28% 2%, 30% 2%, 30% 1%, 36% 1%, 36% 2%, 52% 2%, 52% 1%, 64% 1%, 64% 3%, 80% 3%, 80% 1%, 85% 1%, 85% 0%, 95% 0%, 95% 2%, 100% 2%, 100% 98.5%, 87% 98.5%, 87% 100%, 70% 100%, 70% 99.5%, 56% 99.5%, 56% 99%, 43% 99%, 43% 100%, 25% 100%, 25% 99.5%, 14% 99.5%, 14% 100%, 0% 100%)',
      zIndex: 11
  
    },
    exiting:  { 
      transform: `${trans_set}`, 
      opacity: 0.8,
      backgroundColor: 'rgb(91, 91, 91,0.3)',
      clipPath: 'polygon(0% 0%, 6% 0%, 6% 0%, 28% 0%, 28% 0%, 30% 0%, 30% 0%, 36% 0%, 36% 0%, 52% 0%, 52% 0%, 64% 0%, 64% 0%, 80% 0%, 80% 0%, 85% 0%, 85% 0%, 95% 0%, 95% 0%, 100% 0%, 100% 100%, 87% 100%, 87% 100%, 70% 100%, 70% 100%, 56% 100%, 56% 100%, 43% 100%, 43% 100%, 25% 100%, 25% 100%, 14% 100%, 14% 100%, 0% 100%)',
      zIndex: {index_num}
     },
    exited:   { 
      transform: `${trans_set}`, 
      opacity: 0.8,
      backgroundColor: 'rgb(91, 91, 91,0.3)',
      clipPath: 'polygon(0% 0%, 6% 0%, 6% 0%, 28% 0%, 28% 0%, 30% 0%, 30% 0%, 36% 0%, 36% 0%, 52% 0%, 52% 0%, 64% 0%, 64% 0%, 80% 0%, 80% 0%, 85% 0%, 85% 0%, 95% 0%, 95% 0%, 100% 0%, 100% 100%, 87% 100%, 87% 100%, 70% 100%, 70% 100%, 56% 100%, 56% 100%, 43% 100%, 43% 100%, 25% 100%, 25% 100%, 14% 100%, 14% 100%, 0% 100%)',
      zIndex: {index_num}
    },
  };
  
  const defaultStyle = {
    transform: `${trans_set}`, 
    opacity: 0.8,
    zIndex: {index_num},
    backgroundColor: 'rgb(91, 91, 91,0.3)',
    clipPath: 'polygon(0% 0%, 6% 0%, 6% 0%, 28% 0%, 28% 0%, 30% 0%, 30% 0%, 36% 0%, 36% 0%, 52% 0%, 52% 0%, 64% 0%, 64% 0%, 80% 0%, 80% 0%, 85% 0%, 85% 0%, 95% 0%, 95% 0%, 100% 0%, 100% 100%, 87% 100%, 87% 100%, 70% 100%, 70% 100%, 56% 100%, 56% 100%, 43% 100%, 43% 100%, 25% 100%, 25% 100%, 14% 100%, 14% 100%, 0% 100%)',
    transition: '500ms ease-in-out',

  }
   
  const nodeRef = useRef(null);
  const vidRef = useRef(null);

    const [zoomvideo,setZoomvideo] = useState(false)
    const zoomvideoHandler = () => {
      setZoomvideo(!zoomvideo)
    }


   return (
         <Transition nodeRef={nodeRef} in={zoomvideo} timeout={300}> 
              {state=>(
                <div
                   onClick={zoomvideoHandler} 
                   className={`animations-m-modal ${props.cls}`} 
                   style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                  }}>
                  <video ref={vidRef} width="100%" height="auto" controls preload="metadata" muted >
                      <source src={props.video} type="video/mp4"/>
                  </video>
                  {zoomvideo ? description : null}
                </div>
              )}                
          </Transition>     
   
  );
}

export default AnimationItemMob;



import "../App.css";
import "./Animation.css";
import { useState} from "react";

import AnimationItem from './AnimationItem'

import Midori from '../video/Midori_VHS.mp4'
import Tintagel from '../video/Tintagel.m4v'
import Tea from '../video/FleurTeaRoom.mp4'
import Tour from '../video/Anim.m4v'
import Corona from '../video/corona.mp4'
import Cafe from '../video/Cafemenuloop.m4v'

function Animations(props) { 

  const[selectedvideo, setSelectedvideo] = useState("")
  const selectedVideoHandler = (video) => {
    setSelectedvideo(video)
  }

  return (
    <div className="content-container-animation">
         <div className="animations-info-block"
                           style={{
                            clipPath: `${
                              selectedvideo===""?
                              "polygon(74% 0, 74% 14%, 88% 14%, 88% 27%, 97% 27%, 97% 38%, 86% 38%, 86% 48%, 94% 48%, 94% 74%, 100% 74%, 100% 100%, 0 100%, 0 0)":
                              "polygon(0 0, 0 14%, 0 14%, 0 27%, 0 27%, 0 38%, 0 38%, 0 48%, 0 48%, 0 74%, 0 74%, 0 100%, 0 100%, 0 0)"
                            }`,
                            opacity: `${
                              selectedvideo===""?
                              "1":
                              "0.5"
                            }`
                          }}>
            <div  className="animations-info">
              <div className="anim-though">?</div>
              <p>
              <span className="anim-highlight">CLIPS, STRIPS, PROMOTIONS</span> ... I love animation, it's a hobby of mine and a crucial part of my creative work. While I don't undertake full animation clip projects frequently, when I do, It is always for an interesting and fun project.<br/> Take a look! 
              </p>
            </div>      
         </div>
         <div className="animations-info-fakeblock"/>      
         <div className="animations-screen">
            <AnimationItem video={Midori}     cls={"v-midori"}  selected = {selectedvideo === Midori ? true : false}      onSelectedvideo = {selectedVideoHandler} ls={props.ls}/>
            <AnimationItem video={Tintagel}   cls={"v-tintagel"}selected = {selectedvideo === Tintagel ? true : false}    onSelectedvideo = {selectedVideoHandler} ls={props.ls}/>
            <AnimationItem video={Tea}        cls={"v-tea"}     selected = {selectedvideo === Tea ? true : false}         onSelectedvideo = {selectedVideoHandler} ls={props.ls}/>   
            <AnimationItem video={Tour}       cls={"v-tour"}    selected = {selectedvideo === Tour ? true : false}        onSelectedvideo = {selectedVideoHandler} ls={props.ls}/>
            <AnimationItem video={Corona}     cls={"v-corona"}  selected = {selectedvideo === Corona ? true : false}      onSelectedvideo = {selectedVideoHandler} ls={props.ls}/>  
            <AnimationItem video={Cafe}       cls={"v-cafe"}    selected = {selectedvideo === Cafe ? true : false}        onSelectedvideo = {selectedVideoHandler} ls={props.ls}/>                                   
         </div>
         {selectedvideo !== "" ? <button className="anim-close-vid" onClick={()=> setSelectedvideo("")}>x</button> : null }
    </div>    
  );
}

export default Animations;
import "../App.css";
import { useState, useEffect} from "react";

function Grid(props) {

  const init_pattern = props.pattern;
  const grid_size = props.grid_size


  const [randw, setRandw] = useState([0, -1]);
  const [randh, setRandh] = useState([0, -1]);
  const randHandler = ([status, idx]) => {
     if (status) {
       setRandw([Math.random() * 240 - 140, idx]);
      setRandh([Math.random() * 240 - 140, idx]);
    } else {
      setTimeout(() => {
        setRandw([0, -1]);
        setRandh([0, -1]);
      }, 3000);
    }
  };

  const [fly,setFly] = useState(false)
  const [pattern, setPattern] = useState(init_pattern)
  const transformPattern = () => {  
        setFly(true)
        setTimeout(()=>{
          setFly(false)
        },500)
       setPattern(init_pattern)
  }

  const grid_item_size = `${props.grid_item_size}vw `

  const grid_style = {
    gridTemplateColumns: `${grid_item_size.repeat(grid_size)}`,
  }

  useEffect(()=>{
    transformPattern()
  },[init_pattern])

  return (
    <div className="grid-container" style ={grid_style} onClick={transformPattern}             >
      {pattern.map((item, idx) =>
        item === 1 ? (
          <div
            className="grid-item"
            style={{
              top: `${fly ? Math.random() * 30 - 20 : randh[1] === idx ? randh[0] : 0}vw`,
              left: `${fly ? Math.random() * 30 - 20 : randw[1] === idx ? randw[0] : 0}vw`,
              transition: `${fly ? Math.random()+0.3 : 0.8}s ease-out`,
              opacity: `${fly ? '0.1' : '1'}`,
              transform:`${fly ? 'scale(0.1)' : 'scale(1)'}`,
              width: `${props.grid_item_size}vw`,
              height:`${props.grid_item_size}vw`,
            }}
            onMouseEnter={() => randHandler([true, idx])}
            onMouseLeave={() => randHandler([false, idx])}
            key={idx}
        />
        ) : (
          <div className="grid-item grid-item-transparent" key={idx}
          style={{
            transform:`${fly ? 'scale(0.1)' : 'scale(1)'}`,
            width: `${props.grid_item_size}vw`,
            height:`${props.grid_item_size}vw`,
          }}/>
        )
      )}
    </div>
  );
}

export default Grid;
